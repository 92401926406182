import "../Styles/Homepage.css"
import DiceImage from "../Images/dice.png"
import DragonImage from "../Images/Dragon_Homepage.png"

function Homepage(){
    return (
        <div className="app">
            <main className="main-content">
                <img src={DiceImage} alt="Dice" className="dice-image"/>
                <div className="text-overlay">
                    <h1>Beginne dein Abenteuer!</h1>
                    <button className="start-button">Start</button>
                </div>
            </main>

            <div className="main2-content">
                <section className="dragon-section">
                    <img src={DragonImage} alt="Dragon" className="dragon-image"/>
                    <div className="info-overlay">
                        <h2>Neu bei Dungeons & Dragons?</h2>
                        <p>
                            Im Rollenspiel Dungeons & Dragons geht es um Geschichten aus Welten voller Schwerter und
                            Zauberei. Wie bei allen fantastischen Geschichten ist auch bei D&D die Fantasie die
                            treibende Kraft.
                        </p>
                    </div>
                </section>
            </div>

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section about">
                        <h3>Über uns</h3>
                        <p>
                            DnD Adventures ist Ihre Anlaufstelle für epische Abenteuer. Wir
                            bieten Ihnen die besten Tools und Ressourcen für ein
                            unvergessliches Spielerlebnis.
                        </p>
                    </div>
                    <div className="footer-section links">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="#contact">Kontakt</a></li>
                            <li><a href="#faq">FAQ</a></li>
                        </ul>
                    </div>
                    <div className="footer-section contact">
                        <h3>Kontakt</h3>
                        <p>Email: support@dndadventures.com</p>
                        <p>Telefon: +49 123 456 7890</p>
                        <p>Adresse: Fantasy Straße 42, 12345 Abenteuerstadt</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 DnD Adventures. Alle Rechte vorbehalten.</p>
                </div>
            </footer>
        </div>
    );
}

export default Homepage