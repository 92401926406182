import { Navigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode';


const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    if (isTokenExpired()) {
        localStorage.clear();
        return <Navigate to="/login" replace />;
    }

    return children;
};

const isTokenExpired = () => {
    const token = localStorage.getItem("token");
    if (!token) return true;

    try {
        const decoded = jwtDecode(token);
        const expirationTime = decoded.exp * 1000
        return expirationTime < Date.now();
    } catch (error) {
        return true;
    }
};

export default ProtectedRoute;