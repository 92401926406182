import logo from '../Images/logo.svg';
import '../Styles/global.css';
import {Route, BrowserRouter as Router, Routes, Link} from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import React from "react";
import Header from "./Header";
import Homepage from "./Homepage";
import Maps from "./Maps";
import Adventures from "./Adventures";
import ProtectedRoute from "./ProtectedRoute";

function App() {
    return (
      <Router>
        <Header/>
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/homepage" element={<Homepage/>}/>
            <Route path="/maps" element={<ProtectedRoute><Maps/></ProtectedRoute>}/>
            <Route path="/adventures" element={<ProtectedRoute><Adventures/></ProtectedRoute>}/>
        </Routes>
      </Router>
  );
}


export default App;
