import '../Styles/Maps.css'
import Waldwelt from '../Images/Waldwelt.png'
import Asia from '../Images/Asia-Temple.png'
import China from '../Images/China-Temple.png'
import Model from '../Images/dall-e-logo1.webp'

function Maps() {
    return (
        <div className="app">
            <div className="hero-section">
                <h1>AI generated maps: unique gaming fun</h1>
                <p>
                    In unserem neuen Spiel "DnD: A New Adventure" erwartet die Spieler ein einzigartiges und dynamisches
                    Erlebnis:
                    Die Karten werden mithilfe von OpenAI's DALL-E 3 bei jedem Spiel neu generiert.
                </p>
                <img src={Model} alt="DALL-E 2 Logo" className="dalle-logo"/>
            </div>


            <div className="map-container">

                <div className="map-card">
                    <img src={Waldwelt} alt="Mystical Swamp"/>
                    <h2 id="one">Mystical Swamp</h2>
                    <p>
                        Das Bild zeigt eine magische Sumpflandschaft, in der gigantische, uralte Bäume mit verwinkelten
                        Plattformen und kleinen Baumhäusern in den Ästen zu sehen sind. Der gesamte Ort scheint in ein
                        weiches, ätherisches Licht getaucht zu sein, das von geheimnisvollen Glühkugeln ausgeht, die
                        überall über der Wasseroberfläche schweben.

                        Die Baumhäuser sind liebevoll gestaltet, mit warmem Licht, das aus den Fenstern scheint, und
                        Holzbrücken, die sie miteinander verbinden. Das Wasser darunter ist ruhig und spiegelt das grüne
                        Schimmern der Bäume wider. Kleine Stege und Anlegestellen aus Holz führen ins Wasser und
                        verleihen der Szenerie ein harmonisches, aber mystisches Ambiente.
                    </p>
                </div>

                <div className="map-card">
                    <img src={China} alt="Fantasy Village"/>
                    <h2 id="two">Fantasy Village</h2>
                    <p>
                        Dieses Bild zeigt eine majestätische Stadt, die auf zahlreichen Klippen und Terrassen errichtet
                        wurde und sich über eine mystische Wasserlandschaft erstreckt. Die Gebäude sind aus altem Holz
                        und Stein gefertigt, mit kunstvoll verzierten Dächern und filigranen architektonischen Details,
                        die an eine längst vergangene Ära erinnern.

                        Schmale Brücken und Stege verbinden die verschiedenen Ebenen der Stadt und schaffen ein
                        labyrinthisches Netzwerk von Wegen, das sowohl funktional als auch ästhetisch beeindruckend ist.
                        Die Stadt scheint auf natürliche Weise in die umgebende Landschaft integriert zu sein, mit
                        Wasserfällen, die sich elegant durch die Klippen schlängeln.

                    </p>
                </div>

                <div className="map-card">
                    <img src={Asia} alt="Desert Ruins"/>
                    <h2 id="three">Desert Ruins</h2>
                    <p>
                        Inmitten einer endlosen, glühenden Wüste erhebt sich ein mystischer Tempel, dessen schwarze und
                        goldene Türme aus dem wogenden Meer aus Sand herausragen. Das Bauwerk scheint wie ein Relikt
                        einer vergessenen Zivilisation, gebaut aus poliertem Obsidian und mit kunstvollen goldenen
                        Intarsien verziert, die in der sengenden Sonne glitzern. Der Tempel thront auf einer massiven
                        Plattform, umgeben von symmetrischen Wasserbecken, deren leuchtend türkisfarbenes Wasser einen
                        scharfen Kontrast zur kargen Umgebung bildet.

                        Ein Netz aus steinernen Pfaden und Brücken führt die Besucher über das Wasser, flankiert von
                        Palmen und blühenden Wüstenpflanzen.

                    </p>
                </div>
            </div>

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section about">
                        <h3>Über uns</h3>
                        <p>
                            DnD Adventures ist Ihre Anlaufstelle für epische Abenteuer. Wir
                            bieten Ihnen die besten Tools und Ressourcen für ein
                            unvergessliches Spielerlebnis.
                        </p>
                    </div>
                    <div className="footer-section links">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="#contact">Kontakt</a></li>
                            <li><a href="#faq">FAQ</a></li>
                        </ul>
                    </div>
                    <div className="footer-section contact">
                        <h3>Kontakt</h3>
                        <p>Email: support@dndadventures.com</p>
                        <p>Telefon: +49 123 456 7890</p>
                        <p>Adresse: Fantasy Straße 42, 12345 Abenteuerstadt</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 DnD Adventures. Alle Rechte vorbehalten.</p>
                </div>
            </footer>


        </div>
    );
}

export default Maps; 