import React, {useState} from "react";
import "../Styles/Header.css";
import {Link} from "react-router-dom";
import Sidebar from "./Sidebar";

function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const profileInfo = {
        username: localStorage.getItem("username") || "Guest",
        email: localStorage.getItem("email") || "N/A",
    }

    return (
        <>
        <header className="header">
            <h1><Link to="/">DnD: A New Adventure</Link></h1>
            <nav>
                <ul>
                    <li><Link to="/">Characters</Link></li>
                    <li><Link to="/maps">Maps</Link></li>
                    <li><Link to="/adventures">Adventures</Link></li>
                </ul>
            </nav>
            <div className="profile">
                <button onClick={toggleSidebar}>Profile</button>
            </div>
        </header>
        {isSidebarOpen && (
        <div className="overlay" onClick={toggleSidebar}>
            <Sidebar
                onClose={() => setIsSidebarOpen(false)}
                profileInfo={profileInfo}
            />
        </div>
        )}
        </>
    );
}

export default Header;
