import React, { useState } from "react";
import logo from "../Images/logo.svg";
import "../Styles/Login.css";
import axios from "axios";
import {useNavigate, Link, Routes, Route, BrowserRouter as Router} from "react-router-dom";
import Signup from "./Signup";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL + "/auth/login";

  const handleClick = () => {

  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiUrl, {
        username,
        password,
      });

      const token = response.data.data.token;
      const userid = response.data.data.userId;

      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      localStorage.setItem("userid", userid);
      localStorage.setItem("email", response.data.data.email);

      alert("Login successful!");
      navigate("/Homepage");
      navigate(0);
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed! Please check your username or password.");
    }
  };

  return (

      <div className="login-container">
        <div className="login-box">
          <h1 className="title">Willkommen bei D&D World!</h1>
          <p className="subtitle">
            Bitte logge dich ein oder registriere dich, um dein Abenteuer fortzusetzen!
          </p>
          <form onSubmit={handleLoginSubmit}>
            <h2 id="Title">Anmelden</h2>
            <label htmlFor="username">Benutzername</label>
            <input
                type="text"
                id="username"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
            <label htmlFor="password">Passwort</label>
            <input
                type="password"
                id="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <a href="#" className="forgot-password">
              Passwort vergessen?
            </a>
            <button type="submit">Einloggen</button>
          </form>
          <p className="register-text">
            Noch keinen Account? <Link to="/Signup">Registriere dich!</Link>
          </p>
        </div>
      </div>

  );
};

export default Login;
