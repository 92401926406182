import React, { useState, useEffect } from 'react';
import '../Styles/Sidebar.css';
import {useNavigate} from "react-router-dom";

const Sidebar = ({ onClose, profileInfo }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);
    }, []);

    const handleLogin = () => {
        onClose();
        navigate("/login");
    };

    const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
        window.location.reload();
    };

    return (
        <div className="sidebar" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={onClose}>
                Close
            </button>
            <div className="sidebar-content">
                <h2>Profile Info</h2>
                {isLoggedIn ? (
                    <>
                        <p>Name: {profileInfo.username}</p>
                        <p>Email: {profileInfo.email}</p>
                    </>
                ) : (
                    <p>Please log in to view profile information.</p>
                )}
            </div>
            {isLoggedIn ? (
                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>
            ) : (
                <button className="login-button" onClick={handleLogin}>
                    Login
                </button>
            )}
        </div>
    );
};

export default Sidebar;
