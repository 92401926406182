import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Styles/Adventures.css";
import { useNavigate } from "react-router-dom";

function Adventures() {
    const [games, setGames] = useState([]);
    const [newGame, setNewGame] = useState({ name: "", description: "", userId: "" });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/game/active", {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                });
                if (response.data.status === "Success") {
                    setGames(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching games:", error);
            }
        };

        fetchGames();
    }, []);

    const handleCreateGame = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                name: newGame.name,
                description: newGame.description,
                userId: localStorage.getItem("userid")
            };

            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/game/start",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            setGames((prevGames) => [...prevGames, response.data.data]);
            setNewGame({ name: "", description: "" });
        } catch (error) {
            console.error("Error creating game:", error);
        }
    };

    const handleJoinGame = async (gameId) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + `/game/${gameId}/join`,
                {
                    userId: localStorage.getItem("userid")
                },
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            alert("Successfully joined the game!");

            navigate(`/game/${gameId}`);
        } catch (error) {
            console.error("Error joining game:", error);
        }
    };

    return (
        <div className="content">
            <div className="games">
                <h2>Offene Spiele</h2>
                <div className="game-list">
                    {games.map((game, index) => (
                        <div className="game-item" key={game.gameId || index}>
                            <div className="game-item-row">
                                <h3>{game.name}</h3>
                                <span>Player: {game.userName}</span>
                                <button
                                    className="join-btn"
                                    onClick={() => handleJoinGame(game.gameId)}
                                >
                                    Join
                                </button>
                            </div>
                            <p>{game.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="create-game">
                <h2>Spiel Erstellen</h2>
                <form className="game-creation-form" onSubmit={handleCreateGame}>
                    <input
                        type="text"
                        placeholder="Titel"
                        required
                        value={newGame.name}
                        onChange={(e) => setNewGame({ ...newGame, name: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Beschreibung"
                        required
                        value={newGame.description}
                        onChange={(e) => setNewGame({ ...newGame, description: e.target.value })}
                    />
                    <button type="submit">Erstellen</button>
                </form>
            </div>
        </div>
    );
}

export default Adventures;
